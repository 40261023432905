.termsofuse.page {
  margin: 1em;
}

.termsofuse.page>.narrow>ul>li {
  background-color: inherit;
  margin: auto;
}

.termsofuse.page>.narrow>ul>li>a {
  font-size: 14px;
  min-height: 32px;
  border-bottom-color: rgb(98, 100, 167);
}

.ui-header {
  padding: 1em 0 1em 0;
}

.ui-header__description {
  font-size: 'small';
}