.react-pdf__Page {
    margin-top: 10px;
  }
/* .react-pdf__Page__textContent {
} */

.react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    max-width: -webkit-fill-available;
}


/* The Modal (background) */
.modal {
    display: block;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    border-radius: 0;
  }
  
  /* Modal Content */
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 95%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    border-radius: 8px;
    max-width: 1050px;
  }
  
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  .modal-header {
    padding: 2px 12px;
    background-color: #f5f5f5;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
  
  .modal-body {padding: 2px 12px;}
  
  .modal-footer {
    padding: 2px 12px;
    background-color: #f5f5f5;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }