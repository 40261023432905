.task.page {
    /*margin: 1em;*/
}

.task.page>.narrow>ul>li {
    background-color: inherit;
    margin: auto;
}

.task.page>.narrow>ul>li>a {
    font-size: 14px;
    min-height: 32px;
    border-bottom-color: rgb(98, 100, 167);
}

.sections>* {
    margin: 4rem auto;
}

.ui-header {
    padding: 1em 0 1em 0;
}

.ui-header__description {
    font-size: 'small';
}

.ui-form__label {
    padding: 1em 0 0 0;
}