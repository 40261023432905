.section-margin {
  margin-left: 20px;
}

.mgt-light {
  background-color: inherit !important;
}

.mgt-dark {
  background-color: inherit !important;
}