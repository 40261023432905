/*Reactions*/
.spoke-reaction {
    max-width: 100%;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 20px;
}

.reaction-btn {
    display: inline-block;
    max-width: 100%;
    position: relative;
}

.like-btn {
    color: #777777;
    position: relative;
    cursor: pointer;
    padding: 20px 20px 0 0;
}

.like-btn:hover {
    color: #0db5e4;
}

.like-btn-emo {
    display: inline-block;
    margin: 0 3px 3px 0;
    color: #242424;
}
.like-btn-pressed{
    background: none;
}

.comment-reactions .btn {
    margin-bottom: 3px;
}

.reactions-box {
    width: auto;
    padding: 10px;
    position: absolute;
    top: -60px;
    left: 1px;
    box-shadow: 1px 1px 2px #cccccc, -1px 0px 2px #eeeeee;
    border-radius: 44px 44px;
    display: none;
    background: #ffffff;
}

.reactions-box.comment-box{
    top: -45px;
}

.reaction {
    list-style-type: none;
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1.3em;
}

.like-btn:hover .reactions-box {
    display: block;
}

.reaction-love, .like-btn-love {
    color: rgb(242, 82, 104);
}

.like-btn:hover .reaction {
    opacity: 1;
}

.reaction:hover {
    transform: scale(1.3, 1.3);
    top: 2px
}

.reaction::before {
    display: inline-block;
    color: #ffffff;
    text-align: center;
    min-width: 80%;
    width: max-content;
    line-height: 17px;
    font-size: .6em;
    height: 17px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5px;
    padding-right: 5px;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 20px;
    position: absolute;
    top: -20px;
    opacity: 0;
    transition: opacity .2s ease-in-out 0s;
}

.reaction:hover::before {
    opacity: 1
}

.reaction-like::before {
    content: 'Like'
}

.reaction-dislike::before {
    content: 'Dislike'
}

.reaction-love::before {
    content: 'Love'
}

.reaction-laugh::before {
    content: 'Laugh'
}

.reaction-insightful::before {
    content: 'Insightful'
}

.reaction-sympathy::before {
    content: 'Sympathy'
}

.reaction-curious::before {
    content: 'Curious'
}

.reaction-celebrate::before {
    content: 'Celebrate'
}

.reaction.selected {
    border-radius: 4px;
    border: 1px solid #ddd;
}

.like-btn-text.active {
    color: rgb(88, 144, 255);
}

.like-emo > span {
    display: inline-block;
    margin: 0 2px 0 0;
    text-align: center;
    font-size: 1.1em;
}

.like-details {
    margin-left: 10px;
    color: #9197a3;
    font-size: 12px;
}
/*Reactions*/