.survey.page {
  margin: 1em;
}

.survey.page>.narrow>ul>li {
  background-color: inherit;
  margin: auto;
}

.survey.page>.narrow>ul>li>a {
  font-size: 14px;
  min-height: 32px;
  border-bottom-color: rgb(98, 100, 167);
}

.sections>* {
  margin: 4rem auto;
}

.question {
  padding: 1em 0 0 0;
}

.ui-header {
  padding: 1em 0 1em 0;
}

.ui-header__description {
  font-size: 'small';
}

.survey.page .error {
  border: rgb(196, 49, 75) solid 0.0625rem;
  width: fit-content;
  border-radius: 4px;
  margin: 0px;
  padding: 0px;
}

.survey.page .errorrow {
  border: rgb(196, 49, 75) solid 0.0625rem;
  border-radius: 4px;
  margin: 0px 0px 0px 0px;
  padding: 2px 0px 2px 0px;
}