.compliance.page {
    margin: 1em;
}

.compliance.page>.narrow>ul>li {
    background-color: inherit;
    margin: auto;
}

.compliance.page>.narrow>ul>li>a {
    font-size: 14px;
    min-height: 32px;
    border-bottom-color: rgb(98, 100, 167);
}